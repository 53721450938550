<template>
    <div class="after-service">
        <div class="goods-box flex" v-for="item in goodsInfo" :key="item.id">
            <div class="goods-box-img" :style="{background: 'url('+item.goodsImage+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <div class="right">
                <p class="title">{{item.goodName}}</p>
                <p class="attr">规格</p>
                <div class="goot flex align-center flex-between">
                    <p>¥{{parseFloat(item.promotionPrice/100).toFixed(2)}}</p>
                    <p>×{{item.goodNum}}</p>
                </div>
            </div>
        </div>
        <div class="main-box">
            <div class="f-box" @click="goPath(3)">
                <div class="pic f-t flex align-center">
                    <img src="@/assets/img/order/after/goods.png" alt="" class="l">
                    <p>换货</p>
                    <img src="@/assets/img/order/after/right.png" alt="" class="r">
                </div>
                <p class="desc f-c">仅限于相同商品换货</p>
                <p class="f-b">选择换货操作不会影响您升级种草达人的权益</p>
            </div>
            <div class="s-box" @click="goPath(1)">
                <div class="pic s-t flex align-center">
                    <img src="@/assets/img/order/after/refund.png" alt="" class="l">
                    <p>退货退款</p>
                    <img src="@/assets/img/order/after/right.png" alt="" class="r">
                </div>
                <p class="desc s-c">已收到货，需要退还已收到的货物</p>
            </div>
            <div class="t-box" @click="goPath(2)">
                <div class="pic t-t flex align-center">
                    <img src="@/assets/img/order/after/money.png" alt="" class="l">
                    <p>仅退款</p>
                    <img src="@/assets/img/order/after/right.png" alt="" class="r">
                </div>
                <p class="desc t-c">未收到货(包含未签收)，或与客服协商同意<br>仅退款</p>
            </div>
        </div>
        <div class="tip flex">
            <img src="@/assets/img/order/after/notice.png" alt="">
            <p>若您在交易过程中发现问题可申请售后，您最晚可在确认收货后7天对交易申请售后</p>
        </div>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>
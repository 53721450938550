export default {
    name: "AfterService",
    data() {
        return {
            orderInfo: '',
            goodsInfo: []
        }
    },
    created() {
        this.goodsInfo = JSON.parse(sessionStorage.getItem('orderInfo')).orderGoodItemList;
    },
    methods: {
        goPath(val) {
            this.$router.push({
                path: "/applyAfter",
                query: {
                    type: val
                }
            })
        }
    }
}